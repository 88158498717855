import React, {useState} from 'react';
import {Alert, Button, Card, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import AuthAction from "store/auth/AuthAction";

const LoginForm = props => {
    const dispatch = useDispatch();
    const {register, handleSubmit, watch, setValue, reset, errors} = useForm();
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false)

    async function onSubmitHandler(data, e) {
        e.preventDefault();

        try {
            await dispatch(AuthAction.login(data.email, data.password)).then(r=> {
                props.history.push("/admin");
            })
            setDisabled(true);
            setError(false);
        } catch {
            setError(true);
            setDisabled(false);
        }
    }

    return (
        <Card className={"w-100"} style={{maxWidth: '400px'}}>
            <Card.Body>
                <h2 className={"text-center"}>Přihlášení</h2>
                {error && <Alert variant="danger">
                    Přihlášení se nezdařilo. Skontrolujte zda ste nezadali špatný email nebo heslo.
                </Alert>}
                <Form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Form.Group>
                        <Form.Label>Email:</Form.Label>
                        <Form.Control type={"email"} name={"email"} {...register('email')} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Heslo:</Form.Label>
                        <Form.Control type={"password"} name={"password"} {...register('password')} required/>
                    </Form.Group>
                    <Button className={"w-100"} type={"submit"} disabled={disabled}>Přihlásit</Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default withRouter(LoginForm);