import React from 'react';
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import Logo from 'assets/icons/logo1.svg';
import InternetIcon from 'assets/icons/ikonaInternetFill.svg';
import TVicon from 'assets/icons/ikonaTeleviziaFill.svg';
import ServicIcon from 'assets/icons/servisFill.svg';
import KontaktIcon from 'assets/icons/kontaktFill.svg';

const FrontNav = props => {
    return (
        <Navbar className={"navbar"} expand="lg">
            <Navbar.Brand href="#home">
                <img src={Logo} height={90} width={120} alt=""/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto mr-5">
                    <Nav.Link className={"link d-flex mr-4"} href="https://elnet-group.cz/">
                        <img src={InternetIcon} className={'mr-3'} height={30} width={30} alt=""/>
                        <span className={'mt-1'}>Internetové připojení</span></Nav.Link>
                    <Nav.Link className={"link d-flex mr-4"} href="https://elnet-group.cz/televize/">
                        <img src={TVicon} className={'mr-3'} height={30} width={30} alt=""/>
                        <span className={'mt-1'}>Televize</span></Nav.Link>
                    <Nav.Link className={"link d-flex mr-4"} href="https://elnet-group.cz/servis/">
                        <img src={ServicIcon} className={'mr-3'} height={30} width={30} alt=""/>
                        <span className={'mt-1'}>Servis</span></Nav.Link>
                    <Nav.Link className={"link d-flex mr-4"} href="https://elnet-group.cz/kontakt/">
                        <img src={KontaktIcon} className={'mr-3'} height={30} width={30} alt=""/>
                        <span className={'mt-1'}>Kontakt</span></Nav.Link>
                    {props.userToken ? (
                        <Nav.Link className={"link d-flex"} href="https://elnet-group-dokumenty.sitenode.sk/admin/dokumenty">
                            <img src={ServicIcon} className={'mr-3'} height={30} width={30} alt=""/>
                            <span className={'mt-1'}>Administrace</span>
                        </Nav.Link>
                    ) : (
                        <Nav.Link className={"link mt-1"} href="https://elnet-group-dokumenty.sitenode.sk/prihlaseni">Přihlášení</Nav.Link>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default FrontNav;