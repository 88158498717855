import AdminTableRow from "components/AdminTableRow";
import Loader from "components/Loader";
import useFetchData from "helpers/useFetchData";
import React, {useState} from 'react';
import {Button, Container, Table} from "react-bootstrap";
import {useSelector} from "react-redux";

const AdminTableData = props => {
    // const data = useFetchData(props.db, props.collection);
    const data = useSelector(state => state.files.documents);

    if (!data) {
        return <Loader/>
    }

    return (
            <Table striped bordered hover className={'rounded'} variant="dark">
                <thead>
                <tr>
                    <th>Název souboru</th>
                    <th className={'text-center'}>Editovat</th>
                    <th className={'text-center'}>Smazat</th>
                </tr>
                </thead>
                <tbody>
                {data?.map((data) => (
                    <AdminTableRow key={data.id} data={data}/>
                ))}
                </tbody>
            </Table>
    );
};

export default AdminTableData;