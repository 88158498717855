import React from 'react';

const Footer = props => {
    return (
        <div className="Footer text-center d-flex flex-column justify-content-center">
            <div className="mb-5">
                <h2 className="section__subheading"><b>Kontakt</b></h2>
                <div className="basic-text">
                    Elnet Group s.r.o.
                    Kundratka 1904/20
                    180 00 Praha 8, Libeň
                </div>
            </div>
            <p>Copyright © 2011 - 2021 elnet-group.cz. Všechna práva vyhrazena</p>
        </div>
    );
};

export default Footer;