import FilesAction from "store/files/FilesAction";

class FilesReducer {
    initialState = {
        documents: null
    }

    reducer = (state = this.initialState, action) => {
        switch (action.type) {
            case FilesAction.SET_FILES:
                return {
                    ...state,
                    documents: action.payload
                }
            case FilesAction.PUSH_FILE:
                return {
                    ...state,
                    documents: [...state.documents, action.payload]
                }
            case FilesAction.DELETE_FILE:
                const fileDeleteIndex = state.documents.findIndex(file => file.id === action.payload.id);
                return {
                    ...state,
                    documents: {
                        [fileDeleteIndex]: null
                    }
                }
            case FilesAction.UPDATE_FILE:
                const fileUpdateIndex = state.documents.findIndex(file => file.id === action.payload.id);
                return {
                    ...state,
                    documents: {
                        [fileUpdateIndex]: {
                            ...state.documents[fileUpdateIndex],
                            name: action.payload.name
                        }
                    }
                }

            default:
                return state;
        }
    }
}

export default FilesReducer;