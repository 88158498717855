import AdminSideNavPanel from "components/AdminSideNavPanel";
import Footer from "components/Footer";
import FrontNav from "components/FrontNav";
import Loader from "components/Loader";
import Navigation from "components/Navigation";
import React from 'react';
import {Button, Col, Container, Jumbotron, Nav, Navbar, NavDropdown, Row} from 'react-bootstrap';
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {useLocation} from 'react-router';

const FrontLayout = props => {
    const userToken = useSelector(state => state.auth.user?.stsTokenManager?.accessToken);
    const location = useLocation();
    const admin =
        location.pathname === '/admin'
        || location.pathname === '/admin/dokumenty'
        || location.pathname === '/admin/dokumenty/pridat-novy-dokument';
    return (
        <div className="FrontLayout">
            {userToken && admin ? (
                <>
                    <AdminSideNavPanel/>

                    <section id="contents" style={{minHeight: '100vh'}} className={'position-relative'}>
                        <Navigation/>
                        <Container fluid>
                            {props.children}
                        </Container>
                    </section>
                </>
                ) : (
                    <>
                        <FrontNav userToken={userToken}/>
                        <Container fluid style={{minHeight: '100vh', marginTop: '250px'}}>
                            {props.children}
                        </Container>
                        <Footer/>
                    </>

            )}
        </div>
    );
};

export default withRouter(FrontLayout);