import TableData from "components/TableData";
import React from 'react';
import {Form, FormControl, Nav, Navbar, NavDropdown} from "react-bootstrap";


const Home = props => {

    return (
        <div className="home">
            <h1 className={'my-5 text-center'} style={{fontSize: '62px'}}><b>Dokumenty</b></h1>
            <TableData/>
        </div>
    );
};

export default Home;